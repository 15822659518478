import { Box, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from 'react-router-dom';
import Header from '../../components/header';

const Mindfulness = () => {
    return <>
        <Box sx={{ fontSize: { xs: '13px', sm: '16px' }, display: { xs: 'block', sm: 'none' }, fontWeight: 'bold', textAlign: 'center', marginY: '10px' }}>
            <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ fontFamily: 'BERNIERRegular', fontSize: '18px' }}>positive hotel club house</Box></Link>
        </Box>
        <Header />
        <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', zIndex: 1 }}>
                <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '18px', sm: '24px' } }}>LOVE YOURSELF</Box>
                <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: { xs: '36px', sm: '60px' } }}>MINDFULNESS</Box>
            </Box>
            <img
                src="https://img.positivehotel.io/clubhouse/detail/mindfulness/230421 PH 0377-S.jpg"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                }}
                alt='movement'
            />
        </Box>


        <Container sx={{ paddingX: { xs: 3, sm: 12, lg: 30 }, paddingY: { xs: 8, sm: 15, lg: 20 } }}>
            <Box>
                <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '20px', sm: '36px' } }}>Mindfulness (B1F)</Box>
                <Box sx={{ fontFamily: 'JJGothic-Medium', marginTop: '20px', lineHeight: { xs: '30px', sm: '38px' }, fontSize: { xs: '16px', sm: '20px' } }}>
                    지하 1층에 위치한 Mindfulness는 파지티브호텔의 정신 중 하나인 Love yourself를 실천하는 공간으로, 명상을
                    통해 스스로를 돌아보고, 남에게 배려하는 마음을 길러내는 공간입니다. GOOD IN BAD OUT의 정신을 담은
                    차와 향, 그리고 LEFT RIGHT object studio와 협업한 오브제를 감상하며 스스로를 돌아보는 시간을 가질 수
                    있습니다. 또한 나만이 아닌 주변 사람들에게 마음을 전하는 선물과 파지티브호텔만의 브랜드 제품을 정성껏
                    포장할 수 있는 파지티브호텔만의 기프트 랩핑 스테이션 (Gift Wrapping Station)을 경험할 수 있습니다.</Box>
            </Box>
        </Container >

        <Box sx={{ paddingX: '20px' }}>
            <Box sx={{ position: 'relative', height: { xs: '300px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                <img
                    src="https://img.positivehotel.io/clubhouse/detail/mindfulness/B1_TEABAR_1-S.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                />
            </Box>

            <Box sx={{ position: 'relative', paddingY: { xs: '20px', sm: '80px' } }}>
                <Grid2 container>
                    <Grid2 xs={3} sx={{ paddingRight: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/detail/mindfulness/L1012206-S.jpg"
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                                height: '100%'
                            }}
                            alt='movement'
                        />
                    </Grid2>
                    <Grid2 xs={6}>
                        <Box sx={{ height: '100%' }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/mindfulness/B1_TEABAR_5-S.jpg"
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    height: '100%'
                                }}
                                alt='movement'
                            />
                        </Box>
                    </Grid2>
                    <Grid2 xs={3} sx={{ paddingLeft: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/detail/mindfulness/B1_TEABAR_3-S.jpg"
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                                height: '100%'
                            }}
                            alt='movement'
                        />
                    </Grid2>
                </Grid2>
            </Box>

            <Box sx={{ position: 'relative' }} >
                <Grid2 container>
                    <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*80)' } }}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/detail/mindfulness/L1012146-S.jpg"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            alt='movement'
                        />
                    </Grid2>
                    <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*80)' } }}>
                        <Box sx={{ position: 'relative', height: '100%' }}>
                            <Box sx={{
                                fontFamily: 'JJGothic-Medium',
                                position: 'absolute',
                                top: '20px',
                                left: '20px',
                                color: 'white',
                                fontSize: '16px',
                                display: { xs: 'none', sm: 'block' }
                            }}>
                                주변 사람들에게 마음을 전하는 선물과 제품들을 정성껏 포장할 수 있는 기프트 랩핑 스테이션
                            </Box>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/mindfulness/B1_WRAPINGZONE-S-E.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
            <Box sx={{ marginY: { xs: '30px', sm: '130px' } }}>
                <Box sx={{ width: { xs: '20%', sm: '200px' }, margin: '0 auto' }}>
                    <Link to="/">
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/clubhouse-full-logo-black.png"
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            alt='movement'
                        />
                    </Link>
                </Box>
            </Box>
            <Box sx={{ height: '50px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>© Positive Hotel Club House — 서울특별시 강남구 압구정로 46길 77</Box>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>© Positive Hotel Club House — 77, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, Republic of Korea</Box>
            </Box>
        </Box>
    </>
}

export default Mindfulness;