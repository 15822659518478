import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Container, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

const steps = ['클래스 선택', '날짜 & 시간 선택', '선생님 선택'];

export default function Booking() {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [classSelect, setClassSelect] = useState<number>(0);
    const [value, setValue] = useState<Dayjs | null>(dayjs());

    const clickClass = (index: number) => {
        setClassSelect(index);
    }

    const clickNextButton = () => {
        if (activeStep === 2) {
            return alert('예약 완료');
        }
        setActiveStep(activeStep + 1)
    }

    const classSelectCard = () => {
        return (<Box>
            <Grid2 container gap={2}>
                <Grid2>
                    <Card onClick={() => clickClass(0)} sx={{ maxWidth: 345, border: classSelect === 0 ? '2px solid blue' : 'none', cursor: 'pointer' }}>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image="https://img.positivehotel.io/title/movement.jpg"
                        />
                        <CardContent sx={{ height: '100px' }}>
                            <Typography gutterBottom variant="h5" component="div">
                                울루루요가 1회권
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                1회 수련할 수 있는 상품이며, 요가가 처음이거나 수련을 하시는 분들도 자유롭게 오셔서 수업에 참여하실 수 있습니다.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2>
                    <Card onClick={() => clickClass(1)} sx={{ maxWidth: 345, border: classSelect === 1 ? '2px solid blue' : 'none', cursor: 'pointer' }}>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image="https://img.positivehotel.io/2023/04/05/testimage.png"
                        />
                        <CardContent sx={{ height: '100px' }}>
                            <Typography gutterBottom variant="h5" component="div">
                                1회권 클래스 체험권
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                울루루 1회 클래스 체험권으로
                                시간표 참고 후 참여하시고 싶은 수업에 예약 부탁 드립니다.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </Box>)
    }
    return (
        <Container sx={{ padding: 5 }}>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box sx={{ padding: 10, paddingX: 20 }}>
                {activeStep === 0 ?
                    <>
                        {classSelectCard()}
                    </>
                    : activeStep === 1 ?
                        <Box>
                            <Grid2 container>
                                <Grid2 xs={4}>
                                    <Box>
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            height="140"
                                            image="https://img.positivehotel.io/title/movement.jpg"
                                        />
                                    </Box>
                                </Grid2>
                                <Grid2 xs={8}>
                                    <CardContent sx={{ height: '100px' }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            울루루요가 1회권
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            1회 수련할 수 있는 상품이며, 요가가 처음이거나 수련을 하시는 분들도 자유롭게 오셔서 수업에 참여하실 수 있습니다.
                                        </Typography>
                                    </CardContent>
                                </Grid2>
                            </Grid2>
                            <Grid2 container>
                                <Grid2>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateCalendar']}>
                                            <DemoItem label="날짜 선택">
                                                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid2>
                                <Grid2 sx={{ padding: 10 }}>
                                    <Box>AM</Box>
                                    <Grid2 container gap={3}>
                                        <Button variant='contained' color="info" disabled={true}>6:30</Button>
                                        <Button variant='contained' color="info" disabled={true}>10:00</Button>
                                        <Button variant='contained' color="info" disabled={true}>11:00</Button>
                                    </Grid2>
                                    <br /><br />
                                    <Box>PM</Box>
                                    <Grid2 container gap={3}>
                                        <Button variant='contained' color="info">5:30</Button>
                                        <Button variant='contained' color="info">6:30</Button>
                                        <Button variant='contained' color="info">7:30</Button>
                                        <Button variant='contained' color="info">8:30</Button>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Box>
                        : activeStep === 2 ?
                            <Box>
                                <Grid2 container>
                                    <Grid2 xs={4}>
                                        <Box>
                                            <CardMedia
                                                component="img"
                                                alt="green iguana"
                                                height="140"
                                                image="https://img.positivehotel.io/title/movement.jpg"
                                            />
                                        </Box>
                                    </Grid2>
                                    <Grid2 xs={8}>
                                        <CardContent sx={{ height: '100px' }}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                울루루요가 1회권
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                1회 수련할 수 있는 상품이며, 요가가 처음이거나 수련을 하시는 분들도 자유롭게 오셔서 수업에 참여하실 수 있습니다.
                                            </Typography>
                                        </CardContent>
                                    </Grid2>
                                </Grid2>
                                <Box sx={{ padding: '10px' }}>
                                    <Box>2023년 5월 1일</Box>
                                    <Box>6:30 PM</Box>
                                </Box>
                                <Grid2 container gap={2}>
                                    <Grid2 xs={3}>
                                        <Card onClick={() => clickClass(0)} sx={{ maxWidth: 345, border: classSelect === 0 ? '2px solid blue' : 'none', cursor: 'pointer' }}>
                                            <CardMedia
                                                component="img"
                                                alt="green iguana"
                                                height="230"
                                                image="https://img.positivehotel.io/2023/04/05/teacher1.jpg"
                                            />
                                            <CardContent sx={{ height: '100px' }}>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    여동구
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Instargram @yeo_dong_gu<br />

                                                    25년 경력<br />

                                                    유니버셜요가 티처, 타우요가 마스터, 여동구요가 마스터
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                    <Grid2 xs={3}>
                                        <Card onClick={() => clickClass(1)} sx={{ maxWidth: 345, border: classSelect === 1 ? '2px solid blue' : 'none', cursor: 'pointer' }}>
                                            <CardMedia
                                                component="img"
                                                alt="green iguana"
                                                height="230"
                                                image="https://img.positivehotel.io/2023/04/05/teacher2.jpg"
                                            />
                                            <CardContent sx={{ height: '100px' }}>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    이정은
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    슬림한 요가, 마하하타요가, 타우플로우요가 저자<br />
                                                    현) 하타요가를 바탕으로 한 다양한 스타일의 요가, 명상지도<br />
                                                    Instargram @jeongeun_yoga
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                    <Grid2 xs={3}>
                                        <Card onClick={() => clickClass(2)} sx={{ maxWidth: 345, border: classSelect === 2 ? '2px solid blue' : 'none', cursor: 'pointer' }}>
                                            <CardMedia
                                                component="img"
                                                alt="green iguana"
                                                height="230"
                                                image="https://img.positivehotel.io/2023/04/05/teacher3.jpg"
                                            />
                                            <CardContent sx={{ height: '100px' }}>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    전소희
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    변화하는 환경 속에서도 나의 모습을 있는 그대로 바라보고 사랑하기 위해 온전히 매트 위에 서 봅니다.<br />
                                                    화려하지 않아도 진실하고 견고하게 당신의 옆자리를 지켜가는 그런 강사이고 싶습니다.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                </Grid2>

                            </Box>
                            : activeStep === 3 ?
                                <Box>3</Box>
                                : <Box>4</Box>
                }
                <Box sx={{ textAlign: 'right' }}>
                    <Button onClick={clickNextButton}>다음</Button>
                </Box>
            </Box>

        </Container >
    );
}