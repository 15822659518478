import { Box } from "@mui/material";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';

export default function Video() {

    return (<>
        <Box>
            <Player
                playsInline
                poster="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-5-m.jpg"
                src="https://img.positivehotel.io/video/openvideo.mp4"
            />
        </Box>
    </>
    );
}