import { Box, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from 'react-router-dom';
import Header from '../../components/header';

const Dining = () => {
    return <>
        <Box sx={{ backgroundColor: '#dad5d4' }}>
            <Box sx={{ fontSize: { xs: '13px', sm: '16px' }, display: { xs: 'block', sm: 'none' }, fontWeight: 'bold', textAlign: 'center', paddingY: '10px', backgroundColor: 'white' }}>
                <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ fontFamily: 'BERNIERRegular', fontSize: '18px' }}>positive hotel club house</Box></Link>
            </Box>
            <Header />
            <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', zIndex: 1 }}>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '18px', sm: '24px' } }}>EAT HEALTHY</Box>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: { xs: '36px', sm: '60px' } }}>DINING</Box>
                </Box >
                <img
                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-1.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                />
            </Box >


            <Container sx={{ paddingX: { xs: 3, sm: 12, lg: 30 }, paddingY: { xs: 8, sm: 15, lg: 20 } }}>
                <Box>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '20px', sm: '36px' } }}>Dining (1F)</Box>
                    <Box sx={{ fontFamily: 'JJGothic-Medium', marginTop: '20px', lineHeight: { xs: '30px', sm: '38px' }, fontSize: { xs: '16px', sm: '20px' } }}>
                        파지티브호텔 클럽하우스의 1층에 위치한 Dining은 좋은 것을 넣어주고, 나쁜 것을 덜어주는 GOOD IN BAD
                        OUT 기반의 음식과 식습관을 공유하고 이야기를 나누는 공간입니다. 실제 내 몸의 건강 뿐 아니라 취향이
                        다양한 사람들과 어울리고 좋은 생각들을 공유할 수 있도록 파지티브호텔만의 원테이블 (One Table)이
                        웅장하게 사람들을 맞이합니다. 이 곳은 음식을 주문하는 공간이 아닌 웰니스 푸드 프로그램을 체험하는
                        커뮤니티 공간입니다.</Box>
                </Box>
            </Container>

            <Box sx={{ paddingX: '20px' }}>
                <Box sx={{ position: 'relative', paddingBottom: { xs: '20px', sm: '80px' } }} >
                    <Grid2 container>
                        <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/dining/dining-2.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Grid2>
                        <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/dining/dining-3.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Grid2>
                    </Grid2>
                </Box>

                <Box sx={{ position: 'relative', paddingY: { xs: '20px', sm: '80px' } }}>
                    <Box sx={{ position: 'relative', height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/detail/dining/dining-4.jpg"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            alt='movement'
                        />
                    </Box>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box sx={{ position: 'relative', paddingBottom: { xs: '20px', sm: '80px' }, paddingX: '30px' }} >
                        <Grid2 container spacing={2}>
                            <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*120 + 16px)' } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-5.jpg"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                            <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*120)' } }}>
                                <Box sx={{ height: '50%', paddingBottom: '16px' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/detail/dining/dining-6.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                                <Box sx={{ height: '50%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/detail/dining/dining-7.jpg"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Box sx={{ position: 'relative', paddingY: { xs: '20px', sm: '80px' } }}>
                        <Grid2 container>
                            <Grid2 xs={3} sx={{ paddingRight: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-5.jpg"
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        height: '100%'
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                            <Grid2 xs={6}>
                                <Box sx={{ height: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/detail/dining/dining-7.jpg"
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Grid2>
                            <Grid2 xs={3} sx={{ paddingLeft: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-6.jpg"
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        height: '100%'
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>
                <Box sx={{ position: 'relative', height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/dining/dining-8.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>

                <Box sx={{ position: 'relative', paddingTop: { xs: '20px', sm: '80px' } }}>
                    <Box sx={{ position: 'relative', paddingBottom: { xs: '20px', sm: '80px' } }} >
                        <Grid2 container>
                            <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-9.jpg"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                            <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/dining/dining-10.jpg"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>

                <Box sx={{ marginBottom: { xs: '20px', sm: '100px' } }}>
                    <Box sx={{ width: { xs: 'auto', sm: '200px' }, margin: '0 auto', marginTop: '20px', marginBottom: { xs: '30px', sm: '70px' } }}>
                        <Link to="https://app.catchtable.co.kr/ct/shop/positivehotel" target="_blank" style={{ textDecoration: "none" }}>
                            <Box sx={{
                                fontFamily: 'JJGothic-Medium',
                                border: { xs: '1px solid black', sm: '3px solid black' },
                                padding: '20px 25px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'black',
                                fontSize: { xs: '12px', sm: '16px' },
                                width: { xs: 'fit-content', sm: 'auto' },
                                margin: { xs: '0 auto' },
                            }}>다이닝 예약</Box>
                        </Link>
                    </Box>
                    <Box sx={{ width: { xs: '20%', sm: '200px' }, margin: '0 auto' }}>
                        <Link to="/">
                            <img
                                src="https://img.positivehotel.io/clubhouse/logo/clubhouse-full-logo-black.png"
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Link>
                    </Box>
                </Box>
                <Box sx={{ height: '50px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>© Positive Hotel Club House — 서울특별시 강남구 압구정로 46길 77</Box>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>© Positive Hotel Club House — 77, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, Republic of Korea</Box>
                </Box>
            </Box>
        </Box>
    </>
}

export default Dining;