import { useState } from 'react';
import { Box } from '@mui/material';
import Draggable from "react-draggable";

const Test = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 }); // box의 포지션 값

    const trackPos = (data: any) => {
        setPosition({ x: data.x, y: data.y });
    };

    return <Box >
        <Draggable onDrag={(e, data) => trackPos(data)} >
            <Box className="box" sx={{
                position: 'absolute',
                cursor: 'move',
                userSelect: 'none'
            }}>
                <div style={{
                    backgroundImage: `url("https://en.js.cx/clipart/ball.svg")`,
                    backgroundRepeat: 'no-repeat',
                    width: '50px',
                    height: '50px'
                }}></div>
            </Box>
        </Draggable >
    </Box >
}

export default Test;