import { Box, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header';

const Movement = () => {
    const navigate = useNavigate();
    return <>
        <Box sx={{ fontSize: { xs: '13px', sm: '16px' }, display: { xs: 'block', sm: 'none' }, fontWeight: 'bold', textAlign: 'center', marginY: '10px' }}>
            <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ fontFamily: 'BERNIERRegular', fontSize: '18px' }}>positive hotel club house</Box></Link>
        </Box>
        <Header />
        <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', zIndex: 1 }}>
                <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '18px', sm: '24px' }, color: 'black' }}>SWEAT EVERYDAY</Box>
                <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: { xs: '36px', sm: '60px' }, color: 'black' }}>MOVEMENT</Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <img
                    src="https://img.positivehotel.io/clubhouse/detail/movement/movement-1.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                /></Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img
                    src="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-1-m.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                />
            </Box>

        </Box>

        <Box sx={{ position: 'relative' }}>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box sx={{ position: 'absolute', zIndex: 3 }}>
                    <Container sx={{ paddingLeft: { xs: 3, sm: 12, lg: '30%' }, paddingY: { xs: 8, sm: 15, lg: 10 } }}>
                        <Box sx={{ color: 'white' }}>
                            <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '20px', sm: '36px' } }}>Movement (3F)</Box>
                            <Box sx={{ fontFamily: 'JJGothic-Medium', marginTop: '20px', lineHeight: { xs: '30px', sm: '38px' }, fontSize: { xs: '16px', sm: '18px' } }}>
                                3층에 위치한 Movement는 내 몸과 마음을 동시에 다스리는 공간입니다. 파지티브호텔의 웰니스 센터장이자
                                우리나라 뿐 아니라 전 세계 웰니스 업계에 큰 영향력을 주는 여동구 마스터와 Positive Yoga Crew가 직접
                                고민하여 만든 프로그램인 “Positive Asana”를 통해 내 몸과 마음을 올바르게 쓰는 법을 배우는 공간입니다.
                                이곳 에서만 경험할 수 있는 “Positive Asana”를 만나보길 바랍니다.
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box sx={{ position: 'relative', height: { xs: '500px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/movement-2.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
            </Box>


            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Box sx={{ position: 'absolute', zIndex: 3, top: '20px' }}>
                    <Container sx={{ paddingLeft: { xs: 3, sm: 12, lg: '30%' }, paddingY: { xs: 3, sm: 15, lg: 10 }, backgroundColor: 'white', opacity: '0.8' }}>
                        <Box sx={{ color: 'black' }}>
                            <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '20px', sm: '36px' } }}>Movement (3F)</Box>
                            <Box sx={{ fontFamily: 'JJGothic-Medium', marginTop: '20px', lineHeight: { xs: '30px', sm: '38px' }, fontSize: { xs: '14px', sm: '18px' } }}>
                                3층에 위치한 Movement는 내 몸과 마음을 동시에 다스리는 공간입니다. 파지티브호텔의 웰니스 센터장이자
                                우리나라 뿐 아니라 전 세계 웰니스 업계에 큰 영향력을 주는 여동구 마스터와 Positive Yoga Crew가 직접
                                고민하여 만든 프로그램인 “Positive Asana”를 통해 내 몸과 마음을 올바르게 쓰는 법을 배우는 공간입니다.
                                이곳 에서만 경험할 수 있는 “Positive Asana”를 만나보길 바랍니다.
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box sx={{ position: 'relative', height: { xs: '500px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-2-m.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
            </Box>
        </Box>




        <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'relative', height: { xs: '500px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/movement-3.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-3-m.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
            </Box>
        </Box>
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ position: 'relative', height: { xs: '500px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/movement-4.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-4-m.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>

            </Box>
        </Box>
        <Box sx={{ position: 'relative' }}>
            <Box onClick={() => navigate('/movement/video')}
                sx={{ position: 'relative', height: { xs: '500px', sm: 'calc(var(--vh, 1vh)*100)' } }}>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/movement-5.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/movement/mobile/movement-5-m.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
            </Box>
        </Box>


        <Box sx={{ marginY: { xs: '30px', sm: '130px' } }}>
            <Box sx={{ width: { xs: '20%', sm: '200px' }, margin: '0 auto' }}>
                <Link to="/">
                    <img
                        src="https://img.positivehotel.io/clubhouse/logo/clubhouse-full-logo-black.png"
                        style={{
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Link>
            </Box>
        </Box>
        <Box sx={{ paddingX: '20px' }}>
            <Box sx={{ height: '50px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>© Positive Hotel Club House — 서울특별시 강남구 압구정로 46길 77</Box>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>© Positive Hotel Club House — 77, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, Republic of Korea</Box>
            </Box>
        </Box>
    </>
}

export default Movement;