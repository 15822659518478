import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Home = () => {
    const [hoverMap, setHoverMap] = useState<Array<boolean>>([false, false, false, false])
    const [searchParams] = useSearchParams();
    const page = searchParams.get("p");

    useEffect(() => {
        if (page && page === '1') {
            window.scrollTo({
                top: 1000,
                left: 0,
                behavior: 'auto'
            });
        }
    }, [page])

    function onScroll() {
        if (window.innerWidth <= 600) return;
        //@ts-ignore
        document.getElementsByClassName('header')[0].style.display = window.scrollY + window.innerHeight === document.body.scrollHeight ? 'flex' : 'none'
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return <>
        <Box>
            <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)' }}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <Box sx={{ width: { xs: '150px', sm: '200px' } }}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/mainpage-logo.png"
                            alt='positivehotel-logo'
                            style={{
                                width: '100%',
                                objectFit: 'cover'
                            }} />
                    </Box>
                </Box>

                <Box sx={{ position: 'absolute', bottom: '3%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <ExpandMoreIcon sx={{ color: 'white' }} />
                </Box>
                <img
                    src="https://img.positivehotel.io/clubhouse/main/mainimage.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                />
            </Box>

            <Box sx={{ position: 'relative', display: { xs: 'block', sm: 'none' } }}>
                <Box sx={{ height: '200px' }}>
                    <Box sx={{ height: '200px' }}>
                        <Link to="/mindfulness">
                            <Box
                                sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', }}>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500' }}>LOVE YOURSELF</Box>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600' }}>MINDFULNESS</Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/main/L1012235-S.jpg"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box sx={{ height: '200px' }}>
                        <Link to="/dining">
                            <Box
                                sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', }}>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500' }}>EAT HEALTHY</Box>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600' }}>DINING</Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/main/230130 PH 1252-S.jpg"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box sx={{ height: '200px' }}>
                        <Link to="/listeningroom">
                            <Box
                                sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', }}>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500' }}>EXPERIENCE NEW</Box>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600' }}>LISTENING ROOM</Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/main/DO01012797(n)-S.jpg"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box sx={{ height: '200px' }}>
                        <Link to="/movement">
                            <Box
                                sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', }}>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500' }}>SWEAT EVERYDAY</Box>
                                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600' }}>MOVEMENT</Box>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/main/230224 PH 0586-S.jpg"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Box>
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)', display: { xs: 'none', sm: 'block' } }}>
                <Box className="header" sx={{
                    height: '50px',
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                    width: '100%',
                    background: 'white',
                    textAlign: 'center',
                    display: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: "opacity 0.5s linear",
                }}>
                    <Box>
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/positivehotel-header-logo.png"
                            alt='short-logo'
                            style={{
                                marginLeft: '20px',
                                width: '61px',
                                left: 0,
                                top: '15px'
                            }} />
                    </Box>
                    <Box>
                        <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}>
                            <Box sx={{ fontSize: { xs: '13px', sm: '16px' }, fontWeight: 'bold', fontFamily: 'BERNIERRegular' }}>positive hotel club house</Box>
                        </Link>
                    </Box>
                    <Box>
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/Clubhouse-only-log.png"
                            alt='short-logo'
                            style={{
                                marginRight: '20px',
                                width: '40px',

                                left: 0,
                                top: '15px'
                            }} />
                    </Box>
                </Box>
                <div style={{ height: 'calc(100vh - 100px)' }}>
                    <Grid2 container>
                        <Grid2 xs={3}>
                            <Link to="/mindfulness">
                                <Box
                                    onMouseOver={() => setHoverMap([true, false, false, false])}
                                    onMouseOut={() => setHoverMap([false, false, false, false])}
                                    sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', display: hoverMap[0] ? 'block' : 'none' }}>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: '24px' }}>LOVE YOURSELF</Box>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: '30px' }}>MINDFULNESS</Box>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/L1012235-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[0] ? 'flex' : 'none'
                                            }}
                                            alt='movement'
                                        />
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/L1012235-BW-D-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[0] ? 'none' : 'flex'
                                            }}
                                            alt='movement'
                                        />
                                    </Box>
                                </Box>
                            </Link>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Link to="/dining">
                                <Box onMouseOver={() => setHoverMap([false, true, false, false])}
                                    onMouseOut={() => setHoverMap([false, false, false, false])}
                                    sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', display: hoverMap[1] ? 'block' : 'none' }}>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: '24px' }}>EAT HEALTHY</Box>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: '30px' }}>DINING</Box>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/230130 PH 1252-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[1] ? 'flex' : 'none'
                                            }}
                                            alt='movement'
                                        />
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/230130 PH 1252-BW-D-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[1] ? 'none' : 'flex'
                                            }}
                                            alt='movement'
                                        />
                                    </Box>
                                </Box>
                            </Link>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Link to="/listeningroom">
                                <Box
                                    onMouseOver={() => setHoverMap([false, false, true, false])}
                                    onMouseOut={() => setHoverMap([false, false, false, false])}
                                    sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', display: hoverMap[2] ? 'block' : 'none' }}>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: '24px' }}>EXPERIENCE NEW</Box>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: '30px' }}>LISTENING ROOM</Box>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/DO01012797(n)-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[2] ? 'flex' : 'none'
                                            }}
                                            alt='movement'
                                        />
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/DO01012797(n)-BW-D-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[2] ? 'none' : 'flex'
                                            }}
                                            alt='movement'
                                        />
                                    </Box>
                                </Box>
                            </Link>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Link to="/movement">
                                <Box
                                    onMouseOver={() => setHoverMap([false, false, false, true])}
                                    onMouseOut={() => setHoverMap([false, false, false, false])}
                                    sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                    <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', display: hoverMap[3] ? 'block' : 'none' }}>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: '24px' }}>SWEAT EVERYDAY</Box>
                                        <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: '30px' }}>MOVEMENT</Box>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/230224 PH 0586-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[3] ? 'flex' : 'none'
                                            }}
                                            alt='movement'
                                        />
                                        <img
                                            className='mainImage'
                                            src="https://img.positivehotel.io/clubhouse/main/230224 PH 0586-BW-D-S.jpg"
                                            style={{
                                                height: 'calc(100vh - 50px)',
                                                width: '100%',
                                                objectFit: 'cover',
                                                display: hoverMap[3] ? 'none' : 'flex'
                                            }}
                                            alt='movement'
                                        />
                                    </Box>
                                </Box>
                            </Link>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ height: '50px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: '20px' }}>
                        <Box>© Positive Hotel Club House — 서울특별시 강남구 압구정로 46길 77</Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>© Positive Hotel Club House — 77, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, Republic of Korea</Box>
                    </Box>

                </div>

            </Box>

        </Box >

    </>
}

export default Home;