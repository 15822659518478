import { Box, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from 'react-router-dom';
import Header from '../../components/header';

const Listeningroom = () => {
    return <>
        <Box sx={{ backgroundImage: 'url(https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-bg.jpg)', backgroundSize: 'cover' }}>
            <Box sx={{ fontSize: { xs: '13px', sm: '16px' }, display: { xs: 'block', sm: 'none' }, fontWeight: 'bold', textAlign: 'center', paddingY: '10px', backgroundColor: 'white' }}>
                <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ fontFamily: 'BERNIERRegular', fontSize: '18px' }}>positive hotel club house</Box></Link>
            </Box>
            <Header />
            <Box sx={{ position: 'relative', height: 'calc(var(--vh, 1vh)*100)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ position: 'absolute', color: 'white', textAlign: 'center', zIndex: 1 }}>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '18px', sm: '24px' } }}>EXPERIENCE NEW</Box>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-4500', fontSize: { xs: '36px', sm: '60px' } }}>LISTENING ROOM</Box>
                </Box>
                <img
                    src="https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-1.jpg"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                    alt='movement'
                />
            </Box>

            <Container sx={{ paddingX: { xs: 0, sm: 10, lg: 20 }, paddingY: { xs: 3, sm: 15, lg: 20 } }}>
                <Box sx={{ backgroundColor: 'white', paddingX: '20px', paddingY: '30px', opacity: '0.8' }}>
                    <Box sx={{ fontFamily: 'TuskerGrotesk-6600', fontSize: { xs: '20px', sm: '36px' } }}>Listening Room (2F)</Box>
                    <Box sx={{ fontFamily: 'JJGothic-Medium', marginTop: '20px', lineHeight: { xs: '30px', sm: '34px' }, fontSize: { xs: '16px', sm: '16px' } }}>
                        기존 호텔의 라운지의 음악과는 다르게 재해석된 Sound Therapy, Sound Meditation을 강한 생명력을
                        지닌 지중해 그리스 와인과 함께 새롭게 경험해볼 수 있는 공간입니다. 건강한 가치를 중심으로 작업하는 소리
                        예술가 전형산과 조형예술가 윤세화의 콜렉티브 그룹 ‘야드사이드 YARD.SSIDE’가 Future Wellness에 맞는
                        음악을 담을 파티지브호텔만의 스피커를 직접 제작하였습니다.
                        또한 QUANDOL과 Guest artist들이 ‘GOOD IN, BAD OUT’ 주제에 맞게 제안해주는 리스닝룸 만의
                        플레이리스트를 통해 건강하게 생각하고 커뮤니티와 공유할 수 있는 기회를 제공합니다.</Box>
                </Box>
            </Container>

            <Box sx={{ paddingX: '20px' }}>
                <Box sx={{ position: 'relative', height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*100)' } }}>
                    <img
                        src="https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-2.jpg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        alt='movement'
                    />
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Box sx={{ backgroundColor: 'white', padding: '20px', opacity: '0.8', marginTop: 3 }}>
                    <Box sx={{ fontFamily: 'JJGothic-Medium', lineHeight: { xs: '30px', sm: '20px' }, fontSize: { xs: '13px', sm: '13px' } }}>
                        YARD.SSIDE의 의 커스텀 메이드 스피커 ‘BS3’
                        리스닝룸의 한가운데 위치한 커스텀메이드 스피커 BS3는
                        사운드에 대한 파지티브호텔의 곧은 방향성을 반영합니다.
                        공간 전체를 울림통으로 사용하는 평판(infinite baffle
                        speaker)형의 고출력 스피커로 사운드가 사람들과 공유
                        되고 경험으로서 구현되는 방식과 공간의 정체성을 반영
                        하는 오브제적 요소를 고려하여 제작되었습니다.</Box>
                </Box>



                <Box sx={{ position: 'relative', paddingY: { xs: '20px', sm: '80px' }, paddingX: '20px' }}>
                    <Grid2 container>
                        <Grid2 xs={3} sx={{ paddingRight: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/listeningroom/DO01012732-S.jpg"
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    height: '100%'
                                }}
                                alt='movement'
                            />
                        </Grid2>
                        <Grid2 xs={6}>
                            <Box sx={{ height: '100%' }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/listeningroom/DO01012761-S.jpg"
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        height: '100%'
                                    }}
                                    alt='movement'
                                />
                            </Box>
                        </Grid2>
                        <Grid2 xs={3} sx={{ paddingLeft: { xs: '5px', sm: 2 }, paddingY: { xs: 3, sm: 10 } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/listeningroom/DO01012730-S.jpg"
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    height: '100%'
                                }}
                                alt='movement'
                            />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
            <Box sx={{ paddingX: '20px' }}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box sx={{ position: 'relative', paddingY: { xs: '20px', sm: '80px' } }}>
                        <Grid2 container>
                            <Grid2 xs={3} sx={{ paddingRight: { xs: '5px', sm: 2 } }}>
                                <Box sx={{ height: '50%', position: 'relative' }}>
                                    <Box sx={{ position: 'absolute', bottom: '30px' }}>
                                        <Box sx={{ backgroundColor: 'white', padding: '20px', opacity: '0.8' }}>
                                            <Box sx={{ fontFamily: 'JJGothic-Medium', lineHeight: { xs: '30px', sm: '20px' }, fontSize: { xs: '12px', sm: '13px' } }}>
                                                YARD.SSIDE의 의 커스텀 메이드 스피커 ‘BS3’
                                                리스닝룸의 한가운데 위치한 커스텀메이드 스피커 BS3는
                                                사운드에 대한 파지티브호텔의 곧은 방향성을 반영합니다.
                                                공간 전체를 울림통으로 사용하는 평판(infinite baffle
                                                speaker)형의 고출력 스피커로 사운드가 사람들과 공유
                                                되고 경험으로서 구현되는 방식과 공간의 정체성을 반영
                                                하는 오브제적 요소를 고려하여 제작되었습니다.</Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ height: '50%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-3.jpg"
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%'
                                        }}
                                        alt='movement'
                                    />
                                </Box>

                            </Grid2>
                            <Grid2 xs={6}>
                                <Box sx={{ height: '100%' }}>
                                    <img
                                        src="https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-4.jpg"
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%'
                                        }}
                                        alt='movement'
                                    />
                                </Box>
                            </Grid2>
                            <Grid2 xs={3} sx={{ paddingLeft: { xs: '5px', sm: 2 } }}>
                                <img
                                    src="https://img.positivehotel.io/clubhouse/detail/listeningroom/listening-5.jpg"
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        height: '50%'
                                    }}
                                    alt='movement'
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                </Box>

                <Box sx={{ position: 'relative' }} >
                    <Grid2 container>
                        <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*80)' } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/listeningroom/2F_1-S-E.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Grid2>
                        <Grid2 xs={6} sx={{ height: { xs: '250px', sm: 'calc(var(--vh, 1vh)*80)' } }}>
                            <img
                                src="https://img.positivehotel.io/clubhouse/detail/listeningroom/230421 PH 0118-S.jpg"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Grid2>
                    </Grid2>
                </Box>

                <Box sx={{ marginY: { xs: '30px', sm: '130px' } }}>
                    <Box sx={{ width: { xs: '20%', sm: '200px' }, margin: '0 auto' }}>
                        <Link to="/">
                            <img
                                src="https://img.positivehotel.io/clubhouse/logo/clubhouse-full-logo-black.png"
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                                alt='movement'
                            />
                        </Link>
                    </Box>
                </Box>
                <Box sx={{ height: '50px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>© Positive Hotel Club House — 서울특별시 강남구 압구정로 46길 77</Box>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>© Positive Hotel Club House — 77, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, Republic of Korea</Box>
                </Box>
            </Box>
        </Box>
    </>
}

export default Listeningroom;