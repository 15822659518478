import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
    return (<>
        <Box sx={{ position: 'absolute', zIndex: 4, width: '100%' }}>
            <Box sx={{ height: '50px', position: 'relative', textAlign: 'center', display: { xs: 'none', sm: 'flex' }, alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Link to={'/'}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/positivehotel-header-logo.png"
                            alt='short-logo'
                            style={{
                                marginLeft: '20px',
                                width: '61px',

                                left: 0,
                                top: '15px'
                            }} />
                    </Link>
                </Box>
                <Box><Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ fontSize: '16px', fontWeight: 'bold', fontFamily: 'BERNIERRegular' }}>positive hotel club house</Box></Link></Box>
                <Box>
                    <Link to={'/?p=1'}>
                        <img
                            src="https://img.positivehotel.io/clubhouse/logo/Clubhouse-only-log.png"
                            alt='short-logo'
                            style={{
                                marginRight: '20px',
                                width: '40px',

                                left: 0,
                                top: '15px'
                            }} />
                    </Link>
                </Box>
            </Box>
        </Box>
    </>
    )
}

export default Header;