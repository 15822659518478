import Home from "../pages/home";
import ErrorPage from '../error-page';
import Movement from '../pages/movement';
import ListeningRoom from '../pages/listeningRoom';
import Dining from '../pages/dining';
import MainLayout from './layout/MainLayout';
import Mindfulness from "../pages/mindfulness";
import Test from '../pages/test';
import Booking from '../pages/movement/booking';
import MovementVideo from '../pages/movement/video';
import { useRoutes } from 'react-router-dom';

const Routes = () => {
    return useRoutes([
        {
            path: "/",
            element: <MainLayout />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: "movement",
                    element: <Movement />
                },
                {
                    path: "listeningroom",
                    element: <ListeningRoom />,
                },
                {
                    path: "dining",
                    element: <Dining />,
                },
                {
                    path: "mindfulness",
                    element: <Mindfulness />,
                },
                {
                    path: "test",
                    element: <Test />,
                },
                {
                    path: "movement/booking",
                    element: <Booking />,
                },
                {
                    path: "movement/video",
                    element: <MovementVideo />,
                }
            ],
        }
    ])
};

export default Routes;